/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';

.blue-snackbar {
  background: rgb(5, 151, 83);
  font-weight: bolder;
}

.message-error {
  background-color: var(--color-warn);
  color: #FFFFFF;
}

.dark-theme .message-error {
  background-color: var(--color-warn);
  color: #FFFFFF;
}

/* html, body {
  height: 100%;
  background-color: skyblue;
} */

body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.tituloPantalla {
  background-color: var(--color-primary);
  color: var(--color-texto);
  height: 35px;
}

.contenido-pie {
  max-width: 600px;
  height: 50px;
  margin: 0 auto;
}

.example-full-width-alterno100 {
  width: 100%;
}

.example-full-width-alterno23 {
  width: 23%;
}

.example-full-width-alterno15 {
  width: 15%;
}


.example-full-width-alterno48 {
  width: 48%;
}


.example-full-width-alterno24 {
  width: 24%;
}


.example-full-width-alterno20 {
  width: 20%;
}

.example-full-width-alterno10 {
  width: 10%;
}



/* ESTILOS PRE DEFINIDOS */
.a-main {
  padding: 0 20px;
}

.a-datatable-datos-body-accion {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.a-titulo {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-primary);
  padding: 10px 20px;
  color: var(--color-texto);
  margin-bottom: 25px;
  text-transform: uppercase;
}

.a-title {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--color-primary);
  padding: 10px 20px;
  color: var(--color-texto);
  text-transform: uppercase;
}

.a-example-full-width-alterno-content-row {
  width: 100%;
  display: flex;
  gap: 2%;
}

.a-w1-row {
  width: 100%;
  display: flex;
  gap: 2%;
}

.a-w1-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.a-w1 {
  width: 100%;
}

.a-w1-button {
  width: 100% !important;
}

.a-w2 {
  width: 49%;
}

.a-w3 {
  width: 32%;
}

.a-w4 {
  width: 23.5%;
}

.a-w5 {
  width: 18.4%;
}

.a-w6 {
  width: 15%;
}

.a-w4-diference {
  width: 74.5%;
}

.a-spacing {
  flex-grow: 1;
}

.a-example-full-width-alterno-content-row-buttons {
  width: 100%;
  display: flex;
  gap: 1%;
}

.a-example-full-width-alterno-content-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.a-example-full-width-alterno-content {
  width: 100%;
}

.a-example-full-width-alterno-content-2 {
  /* width: 50%; */
  width: 49%;
}

.a-example-full-width-alterno-content-3 {
  /* width: 25%; */
  width: 23.5%;
}

.a-example-full-width-alterno-content-4 {
  width: 33.3%;
}

.a-example-full-width-alterno-content-5 {
  width: 12.5%;
}

.a-example-full-width-alterno-content-6 {
  width: 10.75%;
}

.a-example-full-width-alterno-content-0_2 {
  width: 74.5%;
}

.a-example-full-width-alterno-content-0_5 {
  width: 66%;
}

.a-example-full-width-alterno-content-0_4 {
  width: 69%;
}

.a-footer {
  height: 60px;
}

.a-example-full-width-alterno-content-row-2 {
  width: 100%;
  display: flex;
  gap: 2%;
}

.a-example-full-width-alterno-content-column-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.a-ajust-content {
  display: contents;
}

.a-center {
  margin: auto;
}

@media (max-width: 1200px) {
  .a-w1-row {
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }

  .a-w1,
  .a-w2,
  .a-w3,
  .a-w4,
  .a-w5,
  .a-w6,
  .a-w4-diference {
    width: 100%;
  }
}

@media (max-width: 600px) {

  .a-example-full-width-alterno-content-row {
    flex-direction: column;
    gap: 25px;
  }

  .a-w1-row {
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }

  .a-w1,
  .a-w2,
  .a-w3,
  .a-w4,
  .a-w5,
  .a-w6 {
    width: 100%;
  }

  .a-example-full-width-alterno-content,
  .a-example-full-width-alterno-content-2,
  .a-example-full-width-alterno-content-3,
  .a-example-full-width-alterno-content-4,
  .a-example-full-width-alterno-content-5,
  .a-example-full-width-alterno-content-6,
  .a-example-full-width-alterno-content-0_2,
  .a-example-full-width-alterno-content-0_5,
  .a-example-full-width-alterno-content-0_4 {
    width: 100%;
  }

  ::ng-deep {
    .p-button {
      width: 100% !important;
    }
  }

  ::ng-deep .p-calendar {
    width: 100%;
  }

  ::ng-deep .p-fieldset {
    width: 100%;
  }
}

/* 
::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #fafafa00;
}

::-webkit-scrollbar-thumb {
  background: rgba(52, 97, 134, 0.568);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
} */

:host ::ng-deep .p-tabview-nav {
  font-size: 11px !important;
}

:host :ng-deep .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 15px 20px !important;
}

:host ::ng-deep .p-accordion-header {
  font-size: 11px !important;
}

:host ::ng-deep .p-accordion-header-link {
  padding: 10px !important;
}

:host ::ng-deep .p-button {
  background-color: var(--color-primary) !important;
}

.a-titulo-grafico {
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
}

.a-grafico {
  background-color: #c8dbec21;
}

.a-footer-grafico {
  font-size: 8px;
  font-weight: 700;
  padding: 10px 0 10px 10px;
  text-transform: uppercase;
}